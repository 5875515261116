import { Route, Routes } from 'react-router-dom';

// Pages
import AlteracaoOrdemServico from '../Pages/AlteracaoOrdemServico';
import CadastroOrdemServico from '../Pages/CadastroOrdemServico';
// import Home from '../Pages/Home';
import Dashboard from '../Pages/Dashboard';
import OrdemServico from '../Pages/OrdemServico';
// import Teste from '../Pages/Teste';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<OrdemServico />} />
      <Route path="/index.html" element={<OrdemServico />} />
      <Route path="/ordem-servico" element={<OrdemServico />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/ordem-servico/incluir" element={<CadastroOrdemServico />} />
      <Route
        path="/ordem-servico/editar/:id"
        element={<AlteracaoOrdemServico />}
      />
      {/* <Route path="/teste" element={<Teste />} /> */}
    </Routes>
  );
};

export default PrivateRoutes;
