import axios, { AxiosInstance } from 'axios';

const BASE_URL: string =
  'https://resttogo.rancheiro.com.br/rest/api_manutencao_espresso';
const OAUTH_URL: string =
  'https://resttogo.rancheiro.com.br/rest/api/oauth2/v1/token';
// const BASE_URL: string = 'http://10.10.1.2:8088/rest/api_manutencao_espresso';
// const OAUTH_URL: string = 'http://10.10.1.2:8088/rest/api/oauth2/v1/token';

const ProtheusApi: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

function getToken(username: string, password: string) {
  return axios.post(
    `${OAUTH_URL}?username=${username}&password=${password}&grant_type=password`,
  );
}

export { getToken };

export default ProtheusApi;
