import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ProtheusApi, { getToken } from '../Connections/protheus';
import { AxiosError, AxiosResponse } from 'axios';
import OAuth2ResponseInstance from '../@types/protheusOAuth2';
import { ProtheusApiParsedError } from '../@types/protheusApiError';

// Types ans Interfaces
export interface AuthContextProps {
  signed: boolean;

  isSigning: boolean;
  setSigningStatus: React.Dispatch<React.SetStateAction<boolean>>;

  error: ProtheusApiParsedError | null;
  setError: React.Dispatch<React.SetStateAction<ProtheusApiParsedError | null>>;

  user: object | null;

  login(
    username: string,
    password: string,
  ): Promise<ProtheusApiParsedError | null>;
  logout(): void;
}

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
);

export const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<object | null>(null);
  const [error, setError] = useState<ProtheusApiParsedError | null>(null);
  const [isSigning, setSigningStatus] = useState<boolean>(false);

  useEffect(() => {
    const storagedUser = localStorage.getItem(
      '@4a931512ce65bdc9ca6808adf92d8783:ee11cbb19052e40b07aac0ca060c23ee',
    );
    const storagedToken = localStorage.getItem(
      '@4a931512ce65bdc9ca6808adf92d8783:94a08da1fecbb6e8b46990538c7b50b2',
    );

    if (storagedUser && storagedToken) {
      setUser(JSON.parse(storagedUser));
      ProtheusApi.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  function login(username: string, password: string) {
    return new Promise<ProtheusApiParsedError | null>((resolve, reject) => {
      getToken(username, password)
        .then((response: AxiosResponse<OAuth2ResponseInstance>) => {
          ProtheusApi.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;

          setUser(response.data);
          setError(null);

          localStorage.setItem(
            '@4a931512ce65bdc9ca6808adf92d8783:ee11cbb19052e40b07aac0ca060c23ee',
            JSON.stringify(response.data),
          );
          localStorage.setItem(
            '@4a931512ce65bdc9ca6808adf92d8783:94a08da1fecbb6e8b46990538c7b50b2',
            response.data.access_token,
          );

          resolve(null);
        })
        .catch((err: AxiosError<ProtheusApiParsedError>) => {
          if (err.code === 'ERR_NETWORK') {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              client: {
                message: 'Não foi possível entrar no sistema',
                explanation: 'Ocorreu um erro de comunicação com o servidor',
                action: 'Entre em contato com o setor de TI',
              },

              support: {
                timestamp: Date.now(),
                originalStatusCode: 503,
                path: '/rest/api/oauth2/v1',
                authenticatedUser: username,
                completeError: err.message,
              },
            });

            return;
          }

          console.log(err.code, err.cause);

          switch (err.response!.status) {
            case 401:
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                client: {
                  message: 'Não foi possível fazer login',
                  explanation: 'Usuário ou senha incorretos',
                  action:
                    'Verifique se o usuário e a senha informados estão corretos ou se seu usuário no Protheus está bloqueado',
                },

                support: {
                  timestamp: Date.now(),
                  originalStatusCode: err.response!.status,
                  path: '/rest/api/oauth2/v1',
                  authenticatedUser: username,
                  completeError: err.message,
                },
              });

              return;

            default:
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                client: {
                  message: 'Não foi possível fazer login',
                  explanation: 'Houve um erro interno no nosso servidor',
                  action: 'Entre em contato com o setor de TI',
                },

                support: {
                  timestamp: Date.now(),
                  originalStatusCode: err.response!.status,
                  path: '/rest/api/oauth2/v1',
                  authenticatedUser: username,
                  completeError: err.message,
                },
              });
          }
        });
    });
  }

  const logout = useCallback(() => {
    setUser(null);

    localStorage.removeItem(
      '@4a931512ce65bdc9ca6808adf92d8783:ee11cbb19052e40b07aac0ca060c23ee',
    );
    localStorage.removeItem(
      '@4a931512ce65bdc9ca6808adf92d8783:94a08da1fecbb6e8b46990538c7b50b2',
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        error,
        user,
        login,
        logout,
        setError,
        isSigning,
        setSigningStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
