import React from 'react';
import { Link } from 'react-router-dom';

// Components
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';

// Icons
// import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
// import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
// import CoffeeMakerRoundedIcon from '@mui/icons-material/CoffeeMakerRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

// Assets
import LogoRancheiro2x from '../../../Assets/rancheiro-2x.png';

const options = [
  // {
  //   title: 'Clientes',
  //   icon: <GroupsRoundedIcon />,
  //   url: '/clientes',
  //   hasDividerBefore: false,
  // },
  // {
  //   title: 'Peças',
  //   icon: <BuildRoundedIcon />,
  //   url: '/ordem-servico',
  //   hasDividerBefore: false,
  // },
  {
    title: 'Ordens de Serviço',
    icon: <DescriptionRoundedIcon />,
    url: '/ordem-servico',
    hasDividerBefore: false,
  },
  {
    title: 'Dashboard',
    icon: <DashboardRoundedIcon />,
    url: '/dashboard',
    hasDividerBefore: false,
  },
];

interface Props {
  opened: boolean;
  toggleDrawer(): void;
}

const Drawer: React.FC<Props> = ({ opened, toggleDrawer }) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={opened}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      PaperProps={{
        sx: {
          borderRadius: '0 1rem 1rem 0',
          bgcolor: '#000000',
        },
      }}
    >
      <Box
        sx={{ width: '18.75rem' }}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <Box flexGrow={1} padding=".5rem">
          <img
            src={LogoRancheiro2x}
            alt="Logo Rancheiro"
            style={{ width: '100%', aspectRatio: '16/5.8' }}
          />
        </Box>

        <Divider />

        <List>
          {options.map((option) => (
            <Link key={option.title} to={option.url} style={{ all: 'unset' }}>
              {option.hasDividerBefore ? (
                <Divider sx={{ marginBottom: '1rem' }} />
              ) : (
                <></>
              )}
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText primary={option.title} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default Drawer;
