import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import columns from '../../Utilities/ColunasOs';

// Components
import {
  Box,
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

// Icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Types and Interfaces
import { OrdemServicoInstance } from '../../@types/ordemServico';
import { LinhaOS } from '../LinhaOS';

interface Props {
  ordens?: OrdemServicoInstance[];
  isFetching: boolean;
  refreshCallback: () => void;
}

const TabelaOS: React.FC<Props> = ({ ordens, isFetching, refreshCallback }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        {isFetching ? (
          [1, 2, 3, 4, 5].map((item) => (
            <Skeleton
              key={item}
              variant="rectangular"
              height="4.5rem"
              width="100vw"
              component="div"
              sx={{ '& + div': { marginTop: '.25rem' } }}
            />
          ))
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordens &&
                ordens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => <LinhaOS key={row.numeroOs} row={row} refreshCallback={refreshCallback} />)}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          borderTop: 'solid 1px #444',
        }}
      >
        <TablePagination
          rowsPerPageOptions={[50, 100, 200, 500]}
          component="div"
          labelRowsPerPage="Linhas por página"
          count={ordens?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Link to="/ordem-servico/incluir">
          <Button
            startIcon={<AddRoundedIcon />}
            size="large"
            color="success"
            variant="text"
            sx={{
              marginLeft: 'auto',
              marginRight: '1rem',
            }}
          >
            Adicionar Ordem de Serviço
          </Button>
        </Link>
      </Box>
    </Paper>
  );
};

export default TabelaOS;
