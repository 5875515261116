import { CamposOrdemServicoInstance } from '../@types/ordemServico';

const columns: readonly CamposOrdemServicoInstance[] = [
  { id: 'numeroOs', label: 'Número OS', minWidth: 170, chip: true },
  { id: 'cgc', label: 'Cod Cliente', minWidth: 100 },
  { id: 'nomeCliente', label: 'Nome Cliente', minWidth: 170 },
  { id: 'tecnico', label: 'Técnico', minWidth: 170 },
  { id: 'dataServico', label: 'Data', minWidth: 170 },
  { id: 'horaInicioServico', label: 'Hora Início', minWidth: 170 },
  { id: 'horaFimServico', label: 'Hora Conclusão', minWidth: 170 },
  { id: 'status', label: 'Status do Serviço', minWidth: 170 },
];

export default columns;
