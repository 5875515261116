import React from 'react';

// Icons
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Components
import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

// Styles
import * as Styles from './styles';

// Interfaces and Types
import { ProtheusApiParsedError } from '../../@types/protheusApiError';

interface Props {
  errorInfo: ProtheusApiParsedError | null;
}

const Error: React.FC<Props> = ({ errorInfo }) => {
  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.Title>
          <ErrorRoundedIcon fontSize="large" sx={{ marginRight: '.5rem' }} />
          {errorInfo && errorInfo.client.message}
        </Styles.Title>

        <Styles.Section>
          <Accordion expanded sx={{ width: '100%' }}>
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Detalhes do erro</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Styles.Label>{'Descrição'}</Styles.Label>
              <Styles.Info>
                {errorInfo && errorInfo.client.explanation}
              </Styles.Info>

              <Styles.Label>{'Solução'}</Styles.Label>
              <Styles.Info>{errorInfo && errorInfo.client.action}</Styles.Info>
            </AccordionDetails>
          </Accordion>
        </Styles.Section>

        <Styles.Section>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Detalhes para o suporte</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Styles.Label>{'Usuário autenticado'}</Styles.Label>
              <Styles.Info>
                {errorInfo && errorInfo.support.authenticatedUser}
              </Styles.Info>

              <Styles.Label>{'Caminho'}</Styles.Label>
              <Styles.Info>{errorInfo && errorInfo.support.path}</Styles.Info>

              <Styles.Label>{'Status Code Original'}</Styles.Label>
              <Styles.Info>
                {errorInfo && errorInfo.support.originalStatusCode}
              </Styles.Info>

              <Styles.Label>{'Timestamp'}</Styles.Label>
              <Styles.Info>
                {errorInfo?.support.timestamp +
                  ' - ' +
                  new Intl.DateTimeFormat('pt-BR', {
                    dateStyle: 'full',
                    timeStyle: 'full',
                    timeZone: 'America/Sao_Paulo',
                  }).format(new Date(errorInfo!.support.timestamp * 1000))}
              </Styles.Info>

              <Styles.Label>{'Erro completo'}</Styles.Label>
              <Styles.Info>
                {errorInfo && errorInfo.support.completeError}
              </Styles.Info>
            </AccordionDetails>
          </Accordion>
        </Styles.Section>
      </Styles.Content>

      <Button
        sx={{ width: '100%', maxWidth: '30rem' }}
        variant="contained"
        href="https://checkout.rancheiro.com.br/"
        // href='https://checkout.rancheiro.com.br/'
      >
        Voltar à página inicial
      </Button>
    </Styles.Container>
  );
};

export default Error;
