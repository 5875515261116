import React, { useState } from 'react';

// Contexts
import { useAuth } from '../../Contexts/AuthContext';

// Components
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Slide,
  SlideProps,
  Snackbar,
  TextField,
} from '@mui/material';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Styles
import * as Styles from './styles';

const Login: React.FC = () => {
  const { login, error, setError, isSigning, setSigningStatus } = useAuth();

  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const [user, setUser] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [userError, setUserError] = useState<boolean>();
  const [passwordError, setPasswordError] = useState<boolean>();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (user && password) {
      setSigningStatus(true);

      login(user, password)
        .catch((error) => {
          setError(error);
          setOpen(true);
        })
        .finally(() => {
          setSigningStatus(false);
        });
    }
  }

  function handleUserInput(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setUser(event.target.value.toLowerCase());
    setUserError(false);
    setPasswordError(false);
  }

  function handlePasswordInput(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setPassword(event.target.value);
    setUserError(false);
    setPasswordError(false);
  }

  return (
    <Styles.Container>
      <Styles.Content elevation={3}>
        <Styles.Header>
          <Styles.RancheiroLogo />
          <Styles.Title> {'Login'}</Styles.Title>
        </Styles.Header>

        <Styles.Form onSubmit={(event) => handleSubmit(event)}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-end' }}>
            <PersonRoundedIcon
              sx={{ color: 'action.active', mr: 1, my: 0.5 }}
            />
            <TextField
              label="Usuário"
              variant="standard"
              onChange={handleUserInput}
              error={userError}
              autoComplete="username"
              required
              disabled={isSigning}
              inputProps={{
                autoFocus: true,
                autoCapitalize: 'off',
              }}
              sx={{ width: '100%' }}
            />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <LockPersonRoundedIcon
              sx={{ color: 'action.active', mr: 1, my: 0.5 }}
            />
            <TextField
              label="Senha"
              variant="standard"
              type={isPasswordVisible ? 'text' : 'password'}
              onChange={handlePasswordInput}
              error={passwordError}
              autoComplete="current-password"
              required
              disabled={isSigning}
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setPasswordVisibility((current) => !current)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    <InputAdornment position="end">
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  </IconButton>
                ),
              }}
            />
          </Box>

          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSigning}
            color="primary"
            sx={{
              width: '100%',
              marginTop: '2rem',
              '@media only screen and (max-width: 720px)': { height: '3rem' },
            }}
          >
            Entrar
          </LoadingButton>

          {/* <Stack direction="row" sx={{width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: ".25rem"}}>
            <FormControlLabel
              control={<Checkbox/>}
              label="Manter conectado"
            />

            <Link href="google.com" target="_blank" underline="hover">Esqueceu a senha?</Link>
          </Stack> */}
        </Styles.Form>

        <Styles.Footer>
          Faça seu login com seu usuário do Protheus
        </Styles.Footer>
      </Styles.Content>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error?.client.explanation}
        </Alert>
      </Snackbar>
    </Styles.Container>
  );
};

export default Login;
