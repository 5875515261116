import React from 'react';

// Components
import { Box } from '@mui/material';
import SimpleAppBar from '../../Components/Surfaces/AppBar';

interface Props {
  disablePadding?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const Page: React.FC<Props> = ({ children, disablePadding = false }) => {
  return (
    <Box component="section" sx={{ height: 'calc(100vh - 8rem)' }}>
      <SimpleAppBar />
      <div style={{ padding: disablePadding ? 'unset' : '1rem' }}>
        {children}
      </div>
    </Box>
  );
};

export default Page;
