import styled from 'styled-components';

export const CustomCard = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  background-color: #000000;
  border-radius: 1rem;

  text-align: center;

  margin-bottom: 1rem;
`;

export const CustomCardValue = styled.h1`
  font-weight: bold;
  font-size: 3rem;
`;

export const OsLine = styled.div`
  width: 100%;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 1rem 1rem;
  border-radius: .5rem;
  background-color: #000000;
  margin-top: 1rem;
`;

export const OsLineLabel = styled.div`
  padding: .25rem 1rem;
  border-radius: 10rem;
  background-color: #111111;
`;

export const OsLineText = styled.span`
`;

export const Divider = styled.div`
  width: 2rem;
`;

export const HalfDivider = styled.div`
  width: 1rem;
`;

export const DatePicker = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border: none;
  border-radius: .5rem;
  padding: .5rem 1rem;
  background-color: transparent;
  outline: none;
  color: #f5f5f5;
`;