import styled from 'styled-components';
import { TableRow } from '@mui/material';

export const Row = styled(TableRow)`
  cursor: pointer;

  &:hover {
    background-color: #222;
  }
`;
