import {
  Document,
  Image,
  Page as PdfPage,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

// Assets
import { OrdemServicoInstance } from '../@types/ordemServico';
import Logo from '../Assets/logo512.png';
import { Masks } from './masks';

const debugMode = false;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
    lineHeight: 1.4,
  },
  section: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexGrow: 1,
    padding: 10,
  },
  rowView: {
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '12.5%',
  },
  logo: {
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'start',
    borderRadius: 4,
  },
  textoEndereco: {
    fontSize: 10,
  },
  textoOs: {
    fontWeight: 'bold',
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 4,
    fontSize: 14,
    backgroundColor: '#222',
    color: '#FFFFFF',
    lineHeight: 1,
  },
  textoDataHora: {
    fontSize: 12,
  },
  infoCliente: {
    width: '100%',
    padding: 10,
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
  },
  infoEquipamento: {
    width: '100%',
    padding: 10,
    marginTop: 10,
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
  },
  orientacoes: {
    width: '100%',
    padding: 10,
    marginTop: 10,
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
  },
  tabela: {
    width: '100%',
    marginTop: 10,
  },
  textoCabecalho: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 4,
  },
  tituloSessao: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  textoSessao: {
    fontSize: 10,
  },
  tituloSessaoPequena: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  textoSessaoPequena: {
    fontSize: 10,
  },
  line: {
    flexGrow: 1,
    height: 16,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  circle: {
    minHeight: 12,
    height: 12,
    minWidth: 12,
    width: 12,
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '50%',
  },
  cutHere: {
    flexGrow: 1,
    maxHeight: 1,
    marginVertical: 4,
    borderBottomColor: '#000',
    borderBottomStyle: 'dashed',
    borderBottomWidth: 1,
  },
  divider: {
    flexGrow: 1,
    maxHeight: 1,
    marginVertical: 4,
    borderBottomColor: '#B8B8B8',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  smallLine: {
    flexGrow: 1,
    height: 12,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  tableRow: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 16,
    marginBottom: 2,
    paddingHorizontal: 10,
    paddingTop: 2,
    backgroundColor: '#F5F5F5',
  },
  tableHeaderText: {
    height: 16,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  tableRowText: {
    height: 16,
    fontSize: 10,
  },
  rodape: {
    width: '100%',
    padding: 10,
    marginTop: 10,
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
    lineHeight: 1.6,
  },
  textoRodape: {
    fontSize: 10,
  },
});

const OsToPdf = (ordemServico: OrdemServicoInstance | undefined) => {
  if (!ordemServico) {
    return <Document></Document>;
  }

  return (
    <Document
      title={`ordem-servico-${ordemServico.numeroOs}`}
      subject={`ordem-servico-${ordemServico.numeroOs}`}
    >
      <PdfPage size="A4" style={styles.page}>
        <View style={styles.header}>
          <View debug={debugMode} style={[styles.section, { flexGrow: 0 }]}>
            <Image src={Logo} style={styles.logo} />
          </View>

          <View
            debug={debugMode}
            style={[
              styles.section,
              { justifyContent: 'space-between', paddingVertical: 12 },
            ]}
          >
            <View>
              <Text style={styles.textoEndereco}>
                {'Rua 137, Nº 426 - Setor Marista, Goiânia - GO'}
              </Text>
              <Text style={styles.textoEndereco}>
                {'CEP: 74170-120 | Telefone Fixo: (62) 3921-7505'}
              </Text>
              <Text style={styles.textoEndereco}>
                {'Edson: (62) 99205-8540 | Claudiomar: (62) 99201-5722'}
              </Text>
              <Text style={styles.textoEndereco}>
                {'Rancheiro: 02.924.249/0001-19 | QualyCream: 02.924.249/0009-76'}
              </Text>
            </View>

            <View>
              <Text style={[styles.textoEndereco]}>
                {'manutencaoespresso@caferancheiro.com.br'}
              </Text>
              {/* <Text style={styles.textoEndereco}>{'www.rancheiro.com.br'}</Text> */}
            </View>
          </View>
          <View
            debug={debugMode}
            style={[
              styles.section,
              {
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexGrow: 0,
                paddingBottom: 12,
              },
            ]}
          >
            <Text style={styles.textoOs}>{`OS ${ordemServico.numeroOs}`}</Text>
            <View style={{ alignItems: 'flex-end' }}>
              <Text
                style={styles.textoDataHora}
              >{`${ordemServico.dataServico} - ${ordemServico.horaInicioServico}`}</Text>
              <Text style={styles.textoDataHora}>Elaborado por Edson</Text>
            </View>
          </View>
        </View>
        <View debug={debugMode} style={[styles.infoCliente, { marginTop: 10 }]}>
          <Text style={styles.textoCabecalho}>{ordemServico.info_cliente.nomeFantasia}</Text>
          <View style={styles.rowView}></View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>{'Razão Social: '}</Text>
            <Text style={styles.textoSessao}>{ordemServico.info_cliente.razaoSocial}</Text>
          </View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>
              {ordemServico.info_cliente.cgc.length === 14 ? "CNPJ: " : "CPF: "}
            </Text>
            <Text style={styles.textoSessao}>
              {
                ordemServico.info_cliente.cgc.length === 14 ?
                Masks.CNPJ(ordemServico.info_cliente.cgc)
                : Masks.CPF(ordemServico.info_cliente.cgc)
              }
            </Text>
            <Text style={[styles.tituloSessao, {marginLeft: 4}]}>{"| Código Protheus: "}</Text>
            <Text style={styles.textoSessao}>{`${ordemServico.info_cliente.codigo}/${ordemServico.info_cliente.loja}`}</Text>
          </View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>{'Endereço: '}</Text>
            <Text style={styles.textoSessao}>
              {`${ordemServico.info_cliente.rua}, ${ordemServico.info_cliente.numero}. ${ordemServico.info_cliente.complemento}.`}
            </Text>
          </View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>{'Bairro: '}</Text>
            <Text style={styles.textoSessao}>{ordemServico.info_cliente.bairro}</Text>
            <Text style={[styles.tituloSessao, { marginLeft: 4 }]}>{'| Cidade: '}</Text>
            <Text style={styles.textoSessao}>{`${ordemServico.info_cliente.cidade} - ${ordemServico.info_cliente.uf}`}</Text>
          </View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>{'CEP: '}</Text>
            <Text style={styles.textoSessao}>{ordemServico.info_cliente.cep}</Text>
            <Text style={[styles.tituloSessao, { marginLeft: 4 }]}>{'| Telefone: '}</Text>
            <Text style={styles.textoSessao}>{`(${ordemServico.info_cliente.ddd}) ${ordemServico.info_cliente.telefone}`}</Text>
          </View>

          <View style={[styles.rowView, { marginTop: 4 }]}>
            <Text style={styles.tituloSessao}>{'Endereço de locação: '}</Text>
            <Text style={styles.textoSessao}>
              {ordemServico.enderecoLocacao || "Não informado"}
            </Text>
          </View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>{'Tratar com: '}</Text>
            <Text style={styles.textoSessao}>
              {ordemServico.nomeResponsavelLocacao || "Não informado"}
            </Text>
          </View>
        </View>

        <View debug={debugMode} style={styles.infoEquipamento}>
          <Text style={styles.textoCabecalho}>{'DADOS DO EQUIPAMENTO'}</Text>
          <View style={styles.rowView}></View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>{'Marca: '}</Text>
            <Text style={styles.textoSessao}>
              {ordemServico.marcaEquipamento || "Não informado"}
            </Text>
          </View>
          <View style={styles.rowView}>
            <Text style={styles.tituloSessao}>{'Modelo: '}</Text>
            <Text style={styles.textoSessao}>
              {ordemServico.modeloEquipamento || "Não informado"}
            </Text>
            <Text style={[styles.tituloSessao, {marginLeft: 2}]}>{'| Nº Série: '}</Text>
            <Text style={styles.textoSessao}>{ordemServico.numeroSerie || "Não informado"}</Text>
          </View>
          <View style={styles.rowView}>
            <Text style={[styles.tituloSessao, { marginTop: 4 }]}>
              {'Motivo da Visita/Previsão de Atendimento:'}
            </Text>
          </View>
          <View style={styles.rowView}>
            <Text style={styles.textoSessao}>
              {ordemServico.motivoVisita || "Não informado"}
            </Text>
          </View>
        </View>
        
        <View debug={debugMode} style={[styles.orientacoes, { marginTop: 10 }]}>
          <View style={{ flexDirection: 'column' }}>
            <View
              debug={debugMode}
              style={{ flexDirection: 'row' }}
            >
              <Text style={styles.tituloSessaoPequena}>{'Técnico: '}</Text>
              <Text style={styles.textoSessaoPequena}>
                {ordemServico.tecnico || "Não informado"}
              </Text>
            </View>

            <View
              debug={debugMode}
              style={{ flexDirection: 'row' }}
            >
              <Text style={styles.tituloSessaoPequena}>{'Descrição do serviço: '}</Text>
              <Text style={styles.textoSessaoPequena}>
                {ordemServico.descricaoServico || "Não informado"}
              </Text>
            </View>
          </View>
        </View>

        <View debug={debugMode} style={styles.tabela}>
          <View
            style={[
              styles.tableRow,
              {
                backgroundColor: '#E1E1E1',
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              },
            ]}
          >
            <Text style={[styles.tableHeaderText, { width: '10%' }]}>
              {'Item'}
            </Text>
            <Text style={[styles.tableHeaderText, { width: '10%' }]}>
              {'Qtde'}
            </Text>
            <Text style={[styles.tableHeaderText, { width: '50%' }]}>
              {'Descrição'}
            </Text>
            <Text style={[styles.tableHeaderText, { width: '15%' }]}>
              {'Vl. Unit'}
            </Text>
            <Text style={[styles.tableHeaderText, { width: '15%' }]}>
              {'Vl. Total'}
            </Text>
          </View>
          {ordemServico.itens && ordemServico.itens.map(item =>
            <View key={item.item} style={styles.tableRow}>
              <Text style={[styles.tableRowText, { width: '10%' }]}>{item.item}</Text>
              <Text style={[styles.tableRowText, { width: '10%' }]}>
                {item.quantidade}
              </Text>
              <Text style={[styles.tableRowText, { width: '50%' }]}>
                {item.descricao}
              </Text>
              <Text style={[styles.tableRowText, { width: '15%' }]}>
                {item.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </Text>
              <Text style={[styles.tableRowText, { width: '15%' }]}>
                {(item.valor*item.quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </Text>
            </View>
          )}
          <View style={[styles.tableRow, { backgroundColor: '#E1E1E1' }]}>
            <Text style={[styles.tableRowText, { width: '10%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '10%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '50%' }]}>
              {'Total das Peças'}
            </Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>
              {ordemServico.itens && ordemServico.itens.reduce((acc, item) => acc + (item.valor*item.quantidade), 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableRowText, { width: '10%' }]}>{'01'}</Text>
            <Text style={[styles.tableRowText, { width: '10%' }]}>
              {'1'}
            </Text>
            <Text style={[styles.tableRowText, { width: '50%' }]}>
              {'Chamado Técnico'}
            </Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>
              {ordemServico.valorChamadoTecnico.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>
              {ordemServico.valorChamadoTecnico.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableRowText, { width: '10%' }]}>
              {'02'}
            </Text>
            <Text style={[styles.tableRowText, { width: '10%' }]}>
              {'1'}
            </Text>
            <Text style={[styles.tableRowText, { width: '50%' }]}>
              {'Mão de Obra'}
            </Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>
              {ordemServico.valorMaoObra.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>
              {ordemServico.valorMaoObra.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Text>
          </View>
          <View style={[styles.tableRow, { backgroundColor: '#E1E1E1' }]}>
            <Text style={[styles.tableRowText, { width: '10%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '10%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '50%' }]}>
              {'Total da M.O.'}
            </Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>
              {(ordemServico.valorMaoObra+ordemServico.valorChamadoTecnico).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Text>
          </View>
          <View
            style={[
              styles.tableRow,
              {
                backgroundColor: '#E1E1E1',
                fontFamily: 'Helvetica-Bold',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              },
            ]}
          >
            <Text style={[styles.tableRowText, { width: '10%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '10%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '50%' }]}>
              {'Total Geral'}
            </Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>{''}</Text>
            <Text style={[styles.tableRowText, { width: '15%' }]}>
              {(ordemServico.valorChamadoTecnico + ordemServico.valorMaoObra + (ordemServico.itens ? ordemServico.itens.reduce((acc, item) => acc + (item.valor*item.quantidade), 0) : 0)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Text>
          </View>
        </View>

        <View
          debug={debugMode}
          style={{
            flexDirection: 'row',
            padding: 10,
            fontSize: 10,
          }}
        >
          <Text style={[styles.tituloSessaoPequena, { marginRight: 5 }]}>
            {'Forma de pagamento:'}
          </Text>
          <View style={styles.circle} />
          <Text style={[styles.textoSessaoPequena, { marginLeft: 4 }]}>
            {'PIX'}
          </Text>
          <View style={[styles.circle, { marginLeft: 8 }]} />
          <Text style={[styles.textoSessaoPequena, { marginLeft: 4 }]}>
            {'Cartão'}
          </Text>
          <View style={[styles.circle, { marginLeft: 8 }]} />
          <Text style={[styles.textoSessaoPequena, { marginLeft: 4 }]}>
            {'Boleto'}
          </Text>
        </View>

        <View
          debug={debugMode}
          style={{
            flexDirection: 'row',
            marginTop: 'auto',
            padding: 10,
            fontSize: 10,
          }}
        >
          <Text style={[styles.tituloSessaoPequena, { marginRight: 5 }]}>
            {'Serviço concluído?'}
          </Text>
          <View style={styles.circle} />
          <Text style={[styles.textoSessaoPequena, { marginLeft: 4 }]}>
            {'Sim'}
          </Text>
          <View style={[styles.circle, { marginLeft: 8 }]} />
          <Text style={[styles.textoSessaoPequena, { marginLeft: 4 }]}>
            {'Não'}
          </Text>
        </View>

        <View
          debug={debugMode}
          style={[styles.rodape, { marginTop: 0, marginBottom: 0 }]}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.textoRodape, { marginLeft: 0 }]}>
              {'Assinatura do Cliente: '}
            </Text>
            <View style={styles.smallLine} />
          </View>
          <Text style={styles.textoRodape}>
            {'Garantias no balcão: Mão de obra 90 dias - Peças 12 meses'}
          </Text>
          <Text style={styles.textoRodape}>
            {
              'Orçamentos não autorizados no prazo de 90 dias sofrerão acréscimo de valores'
            }
          </Text>
        </View>

        <View style={styles.cutHere} />

        <View debug={debugMode} style={[styles.rodape, { marginTop: 0 }]}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.textoRodape}>
              {'Equipamento retirado em: ___/___/20___'}
            </Text>
            <Text
              style={[
                styles.textoRodape,
                { fontFamily: 'Helvetica-Bold', marginLeft: 69 },
              ]}
            >{`O.S. ${ordemServico.numeroOs}`}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.textoRodape}>
              {'Técnico: Edson Rodrigues de Brito'}
            </Text>
            <Text style={[styles.textoRodape, { marginLeft: 100 }]}>
              {'Assinatura do Técnico: '}
            </Text>
            <View style={styles.smallLine} />
          </View>
        </View>
      </PdfPage>
    </Document>
  );
};

export default OsToPdf;
