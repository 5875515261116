import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --dark: #171F28;
    --medium: #202024;
    --light: #F5F5F5;
    --white: #FFFFFF;
    --light-grey: #ffffff11;
    --medium-grey: #919191;
    --grey: #E1E1E644;

    --red: #f44336;
    --green: #4CAF50;
    --yellow: #FFEB3B;
    /* --purple: #8257E5; */
    --purple: #7636ff;
    --blue: #1976D2;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: 150ms;

    scrollbar-color: var(--medium-grey) transparent;
    scrollbar-width: thin;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  body {
    width: 100%;
    min-height: -webkit-fill-available;

    font-family: 'Open Sans', sans-serif;
    
    overflow: hidden;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button,
  textarea,
  input,
  select,
  a,
  h1 {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  ::-webkit-scrollbar {
    width: .25rem;
    height: 0;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--medium-grey);
    border-radius: 15rem;
  }
`;
