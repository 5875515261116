import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ProtheusApi from '../../Connections/protheus';

// Components
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  InputLabelProps,
  Link as MuiLink,
  OutlinedInput,
  Paper,
  Slide,
  SlideProps,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Page from '../../Components/Page';

// Icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// Styles
import {
  ItensOrdemServicoInstance
} from '../../@types/ordemServico';
import * as Styles from './styles';

// Types and Interfaces
type Inputs = {
  cod_os: string;
  cgc: string;
  razaoSocial: string;
  nomeFantasia: string;
  ddd: string;
  telefone: string;
  email: string;
  cep: string;
  cidade: string;
  uf: string;
  rua: string;
  numero: string;
  bairro: string;
  complemento: string;
  entregaAgendada: string;
  tipoCarga: string;
  cobraDescarga: string;
  tipoFrete: string;

  marcaEquipamento: string;
  modeloEquipamento: string;
  numeroSerie: string;

  tecnico: string;
  motivoVisita: string;
  descricaoServico: string;
  enderecoLocacao: string;
  nomeResponsavelLocacao: string;

  valorChamadoTecnico: number;
  valorMaoObra: number;
};

interface Cliente {
  cgc: string;
  razaoSocial: string;
  nomeFantasia: string;
  ddd: string;
  telefone: string;
  email: string;
  cep: string;
  cidade: string;
  uf: string;
  rua: string;
  numero: string;
  bairro: string;
  complemento: string;
  entregaAgendada: string;
  tipoCarga: string;
  cobraDescarga: string;
  tipoFrete: string;
  enderecoLocacao: string;
  nomeResponsavelLocacao: string;
  marcaUltMaquina: string;
  modeloUltMaquina: string;
  serieUltMaquina: string;
}

const currencyFormatOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
};

const CadastroOrdemServico: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const [buscandoCliente, setBuscandoCliente] = useState<boolean>(false);
  const [buscandoOrdemServico, setBuscandoOrdemServico] =
    useState<boolean>(false);

  const [maoObra, setMaoObra] = useState<number>(0);
  const [chamadoTecnico, setChamadoTecnico] = useState<number>(0);
  const [totalPecas, setTotalPecas] = useState<number>(0);
  const [totalGeral, setTotalGeral] = useState<number>(0);

  const [tabelaItensOs, setTabelaItensOs] = useState<
    ItensOrdemServicoInstance[]
  >([]);

  const [descricaoNovaPeca, setDescricaoNovaPeca] = useState<string>('');
  const [quantidadeNovaPeca, setQuantidadeNovaPeca] = useState<number>(1);
  const [valorNovaPeca, setValorNovaPeca] = useState<number>(0);

  const [isSendingOrderStatus, setSendingOrder] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleCancelDialogOpen() {
    setCancelDialogOpen(true);
  }

  function handleCancelDialogClose() {
    setCancelDialogOpen(false);
  }

  function handleSubmitOrder() {
    let data = getValues();

    let requiredFields = [
      data.marcaEquipamento,
      data.cgc,
      data.modeloEquipamento,
      data.numeroSerie
    ];

    requiredFields = requiredFields.filter(field => field.trim().length === 0);

    if (requiredFields.length > 0) {
      alert("Preencha os campos de Marca, Modelo, Série e CPF/CNPJ do cliente")
      return;
    };

    data = {
      ...data,
      valorChamadoTecnico: chamadoTecnico,
      valorMaoObra: maoObra,
    };

    handleDialogClose();
    setSendingOrder(true);

    ProtheusApi.post('/ordem-servico', { ...data, itens: tabelaItensOs })
    .then((response) => {
      setOpen(true);
      setTimeout(() => {
        setSendingOrder(false);
        navigate('/ordem-servico');
      }, 2000);
    })
    .catch((error) => {
      setSendingOrder(false);
      console.log(error);
    });
  }

  function handleCancelOrder() {
    navigate('/ordem-servico');
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function adicionarPeca(peca: ItensOrdemServicoInstance) {
    if (
      descricaoNovaPeca.length <= 0 ||
      quantidadeNovaPeca <= 0 ||
      valorNovaPeca < 0
    ) {
      return;
    }

    setTabelaItensOs([...tabelaItensOs, peca]);
    setDescricaoNovaPeca('');
    setQuantidadeNovaPeca(1);
    setValorNovaPeca(0);
  }

  function removerPeca(peca: ItensOrdemServicoInstance) {
    const newItems = tabelaItensOs.filter(item => item.item !== peca.item);

    setTabelaItensOs(newItems);
  }

  async function buscarCliente() {
    const cpfCnpj = getValues('cgc');

    if (!cpfCnpj || cpfCnpj.length < 11 || cpfCnpj.length > 14) {
      return;
    }

    setBuscandoCliente(true);

    ProtheusApi.get(`/cliente/${cpfCnpj}`)
      .then((response: AxiosResponse<Cliente>) => {
        if (!response.data.cgc) {
          setValue('cgc', "");
          setValue('razaoSocial', "");
          setValue('nomeFantasia', "");
          alert("Cliente não encontrado!")
          return;
        };

        console.log(response.data)

        setValue('cgc', response.data.cgc);
        setValue('razaoSocial', response.data.razaoSocial);
        setValue('nomeFantasia', response.data.nomeFantasia);
        setValue('ddd', response.data.ddd);
        setValue('telefone', response.data.telefone);
        setValue('email', response.data.email);
        setValue('cep', response.data.cep);
        setValue('cidade', response.data.cidade);
        setValue('uf', response.data.uf);
        setValue('rua', response.data.rua);
        setValue('numero', response.data.numero);
        setValue('bairro', response.data.bairro);
        setValue('complemento', response.data.complemento);
        setValue('entregaAgendada', response.data.entregaAgendada);
        setValue('tipoCarga', response.data.tipoCarga);
        setValue('cobraDescarga', response.data.cobraDescarga);
        setValue('tipoFrete', response.data.tipoFrete);
        setValue('enderecoLocacao', response.data.enderecoLocacao);
        setValue('nomeResponsavelLocacao', response.data.nomeResponsavelLocacao);
        setValue('marcaEquipamento', response.data.marcaUltMaquina);
        setValue('modeloEquipamento', response.data.modeloUltMaquina);
        setValue('numeroSerie', response.data.serieUltMaquina);
      })
      .catch((error) => {
        setValue('cgc', "");
        setValue('razaoSocial', "");
        setValue('nomeFantasia', "");
        alert("Cliente não encontrado!")
        console.log(error);
      })
      .finally(() => setBuscandoCliente(false));
  }

  useEffect(() => {
    recalcularValores();

    return recalcularValores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelaItensOs, chamadoTecnico, maoObra]);

  function recalcularValores() {
    let total: number = 0;

    total = tabelaItensOs.reduce((sum, item) => {
      return sum + (item.quantidade * item.valor);
    }, 0);

    setTotalPecas(total);

    total += maoObra + chamadoTecnico;

    setTotalGeral(total);
  }

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

  const inputLabelProps: InputLabelProps = {
    shrink: true,
  };

  return (
    <Page disablePadding>
      <Styles.Form
        component="form"
        onSubmit={event => event.preventDefault()}
        overflow="hidden"
      >
        <Grid container spacing={2}>
          <Grid xs={6} item columnGap={2} paddingRight={2}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
              sx={{
                height: '100%',
              }}
            >
              <MuiLink underline="hover" color="inherit" href="/">
                Início
              </MuiLink>
              <MuiLink underline="hover" color="inherit" href="/ordem-servico">
                Ordens de Serviço
              </MuiLink>
              <Typography color="text.primary">
                Incluir Ordem de Serviço
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid xs={6} item columnGap={2} paddingRight={2}>
            <Stack direction="row-reverse" spacing={2}>
              <LoadingButton
                size="large"
                variant="contained"
                color="success"
                loading={isSendingOrderStatus}
                onClick={handleDialogOpen}
                // type="submit"
              >
                {'Gravar O.S.'}
              </LoadingButton>
              <Button
                size="large"
                variant="text"
                color="error"
                onClick={handleCancelDialogOpen}
              >
                {'Cancelar'}
              </Button>
            </Stack>
          </Grid>

          <Grid xs={4} item>
            <Typography fontSize={18} fontWeight="bold" mb={2}>
              Dados do Serviço
            </Typography>
            <Paper
              elevation={3}
              sx={{
                padding: '1rem',
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 14rem)',
              }}
            >
              <Stack direction="column" spacing={3}>
                <Typography>Dados do Cliente</Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Controller
                    control={control}
                    name="cgc"
                    defaultValue=""
                    rules={{
                      required: 'Campo obrigatório',
                      minLength: {
                        value: 11,
                        message: 'Informe um CPF ou CNPJ válido',
                      },
                      maxLength: {
                        value: 14,
                        message: 'Informe um CPF ou CNPJ válido',
                      },
                      pattern: {
                        value:
                          /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
                        message: 'Informe um CPF ou CNPJ válido',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        label="CPF ou CNPJ"
                        type="number"
                        error={!!errors.cgc}
                        helperText={errors.cgc ? errors.cgc.message : ''}
                      />
                    )}
                  />
                  <IconButton
                    aria-label="pesquisar"
                    color="success"
                    onClick={() => buscarCliente()}
                    sx={{ height: '100%', aspectRatio: 1 }}
                    disabled={!!errors.cgc}
                  >
                    {buscandoCliente ? (
                      <CircularProgress size={24} color="success" />
                    ) : (
                      <SearchRoundedIcon />
                    )}
                  </IconButton>
                </Stack>
                <TextField
                  {...register('razaoSocial')}
                  id="razaoSocial"
                  label="Razão Social / Nome Completo"
                  variant="outlined"
                  InputLabelProps={inputLabelProps}
                  disabled
                />
                <TextField
                  {...register('nomeFantasia')}
                  id="nomeFantasia"
                  label="Nome Fantasia / Nome"
                  variant="outlined"
                  InputLabelProps={inputLabelProps}
                  disabled
                />

                <Typography>Dados da Máquina</Typography>
                <Controller
                  control={control}
                  name="marcaEquipamento"
                  defaultValue=""
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 3,
                      message: 'Informe uma marca',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="marcaEquipamento"
                      label="Marca"
                      variant="outlined"
                      error={!!errors.marcaEquipamento}
                      helperText={
                        errors.marcaEquipamento
                          ? errors.marcaEquipamento.message
                          : ''
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="modeloEquipamento"
                  defaultValue=""
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 3,
                      message: 'Informe o modelo',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="modeloEquipamento"
                      label="Modelo"
                      variant="outlined"
                      error={!!errors.modeloEquipamento}
                      helperText={
                        errors.modeloEquipamento
                          ? errors.modeloEquipamento.message
                          : ''
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="numeroSerie"
                  defaultValue=""
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 1,
                      message: 'Informe a série',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="numeroSerie"
                      label="Série"
                      variant="outlined"
                      error={!!errors.numeroSerie}
                      helperText={
                        errors.numeroSerie ? errors.numeroSerie.message : ''
                      }
                    />
                  )}
                />

                <Typography>Dados do Técnico</Typography>
                <Controller
                  control={control}
                  name="tecnico"
                  defaultValue=""
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 2,
                      message: 'Informe o nome do técnico',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="tecnico"
                      label="Técnico"
                      variant="outlined"
                      error={!!errors.tecnico}
                      helperText={errors.tecnico ? errors.tecnico.message : ''}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="motivoVisita"
                  defaultValue=""
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 11,
                      message: 'Dê mais detalhes do motivo da visita',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="motivoVisita"
                      label="Motivo da Visita"
                      variant="outlined"
                      error={!!errors.motivoVisita}
                      helperText={
                        errors.motivoVisita ? errors.motivoVisita.message : ''
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="descricaoServico"
                  defaultValue=""
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 11,
                      message: 'Dê mais detalhes do serviço',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="descricaoServico"
                      label="Descrição do serviço"
                      variant="outlined"
                      error={!!errors.descricaoServico}
                      helperText={
                        errors.descricaoServico
                          ? errors.descricaoServico.message
                          : ''
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="enderecoLocacao"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="enderecoLocacao"
                      label="Endereço de locação"
                      variant="outlined"
                      error={!!errors.enderecoLocacao}
                      helperText={
                        errors.enderecoLocacao
                          ? errors.enderecoLocacao.message
                          : ''
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="nomeResponsavelLocacao"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="nomeResponsavelLocacao"
                      label="Aos cuidados de"
                      variant="outlined"
                      error={!!errors.nomeResponsavelLocacao}
                      helperText={
                        errors.nomeResponsavelLocacao
                          ? errors.nomeResponsavelLocacao.message
                          : ''
                      }
                    />
                  )}
                />

              </Stack>
            </Paper>
          </Grid>
          <Grid xs={8} item>
            <Typography fontWeight="bold" fontSize={18} mb={2}>
              Orçamento
            </Typography>
            <Paper
              elevation={3}
              sx={{
                padding: '1rem',
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 14rem)',
              }}
            >
              <Stack direction="column" spacing={3}>
                <Typography>Mão de Obra</Typography>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="valorChamadoTecnico">
                      Valor do Chamado Técnico
                    </InputLabel>
                    <OutlinedInput
                      id="valorChamadoTecnico"
                      startAdornment={
                        <InputAdornment position="start">R$</InputAdornment>
                      }
                      label="Valor do Chamado Técnico"
                      value={chamadoTecnico}
                      type="number"
                      inputMode="numeric"
                      onChange={(event) =>
                        setChamadoTecnico(
                          parseFloat(event.currentTarget.value),
                        )
                      }
                      required
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="valorMaoObra">
                      Valor da Mão de Obra
                    </InputLabel>
                    <OutlinedInput
                      id="valorMaoObra"
                      startAdornment={
                        <InputAdornment position="start">R$</InputAdornment>
                      }
                      label="Valor da Mão de Obra"
                      type="number"
                      inputMode="numeric"
                      value={maoObra}
                      onChange={(event) =>
                        setMaoObra(parseFloat(event.currentTarget.value) || 0)
                      }
                      required
                    />
                  </FormControl>
                </Stack>
                <Typography>Peças</Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TextField
                    fullWidth
                    id="descricaoPeca"
                    label="Descrição da Peça"
                    variant="outlined"
                    value={descricaoNovaPeca}
                    onChange={(event) =>
                      setDescricaoNovaPeca(event.currentTarget.value)
                    }
                  />
                  <TextField
                    id="quantidadePeca"
                    label="Quantidade"
                    variant="outlined"
                    type="number"
                    inputProps={{ min: 1 }}
                    inputMode="numeric"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={quantidadeNovaPeca}
                    onChange={(event) =>
                      setQuantidadeNovaPeca(
                        parseInt(event.currentTarget.value) || 0,
                      )
                    }
                  />
                  <FormControl>
                    <InputLabel htmlFor="valorChamadoTecnico">
                      Valor da Peça
                    </InputLabel>
                    <OutlinedInput
                      id="valorChamadoTecnico"
                      startAdornment={
                        <InputAdornment position="start">R$</InputAdornment>
                      }
                      label="Valor da Peça"
                      inputMode="numeric"
                      type='number'
                      value={valorNovaPeca}
                      onChange={(event) =>
                        setValorNovaPeca(
                          parseFloat(event.currentTarget.value),
                        )
                      }
                    />
                  </FormControl>
                  <IconButton
                    aria-label="adicionar"
                    color="success"
                    size="large"
                    disabled={
                      descricaoNovaPeca.length <= 0 ||
                      quantidadeNovaPeca <= 0 ||
                      valorNovaPeca < 0
                    }
                    sx={{ height: '100%', aspectRatio: 1 }}
                    onClick={() =>
                      adicionarPeca({
                        cod_os: '',
                        item: tabelaItensOs.length.toString().padStart(2, '0'),
                        descricao: descricaoNovaPeca,
                        quantidade: quantidadeNovaPeca,
                        valor: valorNovaPeca,
                      })
                    }
                  >
                    <AddRoundedIcon />
                  </IconButton>
                </Stack>
                <Typography>Itens da O.S.</Typography>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Descrição
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        Quantidade
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        Valor Unitário
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        Valor Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>{'Chamado Técnico'}</TableCell>
                      <TableCell align="right">{1}</TableCell>
                      <TableCell align="right">
                        {(chamadoTecnico || 0).toLocaleString(
                          'pt-BR',
                          currencyFormatOptions,
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {(chamadoTecnico || 0).toLocaleString(
                          'pt-BR',
                          currencyFormatOptions,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>{'Mão de Obra'}</TableCell>
                      <TableCell align="right">{1}</TableCell>
                      <TableCell align="right">
                        {(maoObra || 0).toLocaleString(
                          'pt-BR',
                          currencyFormatOptions,
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {(maoObra || 0).toLocaleString(
                          'pt-BR',
                          currencyFormatOptions,
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="right"
                        sx={{
                          fontWeight: 'bold',
                          border: 'none',
                          color: '#4CAF50',
                        }}
                      >
                        Total da Mão de Obra
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: 'bold',
                          border: 'none',
                          color: '#4CAF50',
                        }}
                      >
                        {(maoObra + chamadoTecnico || 0).toLocaleString(
                          'pt-BR',
                          currencyFormatOptions,
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ border: 'none' }}></TableCell>
                    </TableRow>

                    {tabelaItensOs.map((peca) => (
                      <TableRow
                        key={peca.item}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{peca.descricao}</TableCell>
                        <TableCell align="right">{peca.quantidade}</TableCell>
                        <TableCell align="right">
                          {peca.valor.toLocaleString(
                            'pt-BR',
                            currencyFormatOptions,
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {(peca.valor * peca.quantidade).toLocaleString(
                            'pt-BR',
                            currencyFormatOptions,
                          )}
                        </TableCell>
                        
                        <TableCell align='left'>
                          <IconButton color='error' size='small' onClick={() => removerPeca(peca)}>
                            <DeleteRoundedIcon color='error' fontSize='small' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="right"
                        sx={{
                          fontWeight: 'bold',
                          border: 'none',
                          color: '#4CAF50',
                        }}
                      >
                        Total das Peças
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: 'bold',
                          border: 'none',
                          color: '#4CAF50',
                        }}
                      >
                        {totalPecas.toLocaleString(
                          'pt-BR',
                          currencyFormatOptions,
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ border: 'none' }}></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="right"
                        sx={{
                          fontWeight: 'bold',
                          border: 'none',
                          color: '#4CAF50',
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: 'bold',
                          border: 'none',
                          color: '#4CAF50',
                        }}
                      >
                        {totalGeral.toLocaleString(
                          'pt-BR',
                          currencyFormatOptions,
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Styles.Form>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deseja realmente incluir a Ordem de Serviço?'}
        </DialogTitle>
        <DialogActions sx={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
          <Button onClick={handleSubmitOrder}>Sim</Button>
          <Button onClick={handleDialogClose} autoFocus variant="contained">
            Voltar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cancelDialogOpen}
        onClose={handleCancelDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deseja realmente cancelar a inclusão da OS?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao cancelar a inclusão, todos os dados inseridos serão perdidos
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
          <Button onClick={handleCancelOrder}>Cancelar inclusão</Button>
          <Button
            onClick={handleCancelDialogClose}
            autoFocus
            variant="contained"
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Ordem de serviço criada com sucesso
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{
          backgroundColor: '#000000dd',
          color: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isSendingOrderStatus}
      >
        {open ? (
          <CheckCircleRoundedIcon
            color="success"
            sx={{ width: '4rem', height: '4rem' }}
          />
        ) : (
          <CircularProgress color="primary" />
        )}
      </Backdrop>

      <Backdrop
        sx={{
          backgroundColor: '#000000dd',
          color: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={buscandoOrdemServico}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Page>
  );
};

export default CadastroOrdemServico;
