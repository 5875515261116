import React, { createContext, useContext, useState } from 'react';

// Components
import Error from '../Components/Error';

// Interfaces and Types
import { ProtheusApiParsedError } from '../@types/protheusApiError';

export interface ErrorBoundaryContextProps {
  setErrorStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorInfo: React.Dispatch<
    React.SetStateAction<ProtheusApiParsedError | null>
  >;
  errorInfo: ProtheusApiParsedError | null;
}

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

export const ErrorBoundaryContext = createContext<ErrorBoundaryContextProps>(
  {} as ErrorBoundaryContextProps,
);

export const useError = () => useContext(ErrorBoundaryContext);

const ErrorProvider: React.FC<Props> = ({ children }) => {
  const [hasError, setErrorStatus] = useState<boolean>(false);
  const [errorInfo, setErrorInfo] = useState<ProtheusApiParsedError | null>(
    null,
  );

  return (
    <ErrorBoundaryContext.Provider
      value={{ setErrorStatus, setErrorInfo, errorInfo }}
    >
      {hasError ? <Error errorInfo={errorInfo} /> : children}
    </ErrorBoundaryContext.Provider>
  );
};

export default ErrorProvider;
