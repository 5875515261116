import { useAuth } from '../Contexts/AuthContext';

// Routes
import PrivateRoutes from './private.routes';
import PublicRoutes from './public.routes';

const Routes = () => {
  const { signed } = useAuth();

  // return signed || process.env.NODE_ENV === 'development' ? (
  return signed ? <PrivateRoutes /> : <PublicRoutes />;
};

export default Routes;
