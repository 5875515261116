import styled from 'styled-components';

import StackedPeaks from '../../Assets/stacked-peaks.svg';

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
  padding: 2rem 0;

  background-image: url(${StackedPeaks});
  background-position: top;
  background-size: cover;
  image-rendering: optimizeQuality;

  @media only screen and (max-width: 720px) {
    padding: 1rem;
  }
`;

export const Content = styled.section`
  width: 30rem;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow-y: auto;
  border-radius: 0.25rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & + section {
    margin-top: 1rem;
    padding-bottom: 0.1rem;
  }
`;

export const Title = styled.h1`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid 0.1rem var(--medium-grey);
  border-radius: 0.25rem 0.25rem 0 0;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;

  /* background-color: var(--light); */
  color: var(--blue);

  font-weight: bold;
  font-size: 1.3rem;

  @media only screen and (max-width: 720px) {
    padding: 0.5rem;
    margin-bottom: 2rem;
  }
`;

export const Label = styled.h1`
  font-weight: bold;
  font-size: 1.1rem;
`;

export const Info = styled.div`
  border-left: solid 0.25rem var(--medium-grey);
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
`;
