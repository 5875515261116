import { Box, Paper } from '@mui/material';
import styled from 'styled-components';

export const Form = styled(Box)`
  width: 100%;
  max-height: calc(100vh - 5rem);

  justify-content: center;
  align-items: center;
  padding: 1rem;

  overflow-y: auto;

  @media screen and (maxwidth: 1024px) {
    padding: 0.5rem;
  }
`;

export const FormGroup = styled(Paper)`
  width: 100%;

  padding: 1rem;

  @media screen and (maxwidth: 1024px) {
  }
`;
