import { createTheme } from '@mui/material';

export default createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    body1: {
      fontSize: '1rem',
      '@media (max-width:600px)': {
        fontSize: '.875rem',
      },
    },
  },
  palette: {
    mode: 'light',
    common: {
      black: '#111111',
      white: '#F5F5F5',
    },
    primary: {
      main: '#7636ff',
      contrastText: '#F5F5F5',
    },
    secondary: {
      main: '#1976D2',
      contrastText: '#F5F5F5',
    },
    error: {
      main: '#f44336',
      contrastText: '#F5F5F5',
    },
    warning: {
      main: '#FFEB3B',
      contrastText: '#F5F5F5',
    },
    info: {
      main: '#7636ff',
      contrastText: '#F5F5F5',
    },
    success: {
      main: '#4CAF50',
      contrastText: '#F5F5F5',
    },
    text: {
      primary: '#111111',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F5F5F5',
    },
  },
});
