import { ThemeProvider, CssBaseline } from '@mui/material';

// Contexts
import ErrorProvider from './Contexts/ErrorBoundaryContext';
import AuthProvider from './Contexts/AuthContext';
import { dark } from './Styles/Themes';

// Routes
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes/routes';

// Styles
import GlobalStyle from './Styles/global';

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <ThemeProvider theme={dark}>
        <CssBaseline />
        <AuthProvider>
          <BrowserRouter>
            <ErrorProvider>
              <Routes />
            </ErrorProvider>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
