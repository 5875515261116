import React, { useEffect, useState } from 'react';
import ProtheusApi from '../../Connections/protheus';

// Components
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Stack,
  ToggleButton,
  Typography,
} from '@mui/material';
import Page from '../../Components/Page';
import TabelaOS from '../../Components/TabelaOS';

// Icons
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// Types and Interfaces
import { AxiosResponse } from 'axios';
import { OrdemServicoInstance } from '../../@types/ordemServico';

const OrdemServico: React.FC = () => {
  const [isFetching, setFetching] = useState<boolean>(true);
  const [apenasPendentes, setApenasPendentes] = useState<boolean>(false);
  const [ordensServico, setOrdensServico] = useState<OrdemServicoInstance[]>();
  const [ordensFiltradas, setOrdensFiltradas] = useState<OrdemServicoInstance[]>();

  useEffect(() => {
    buscarOrdensServico();
  }, []);

  useEffect(() => {
    if (ordensServico) {
      let newOrdersList = apenasPendentes ? ordensServico.filter((order) => order.status === '1') : ordensServico;

      setOrdensFiltradas(newOrdersList);
    }
  }, [apenasPendentes]);

  function handleSearchFilter(text: string) {
    const regex = new RegExp(`^${text}`, 'igm');
    const newOrdersList = ordensServico?.filter(
      (ordem) =>
        regex.test(ordem.nomeCliente) ||
        regex.test(ordem.numeroOs) ||
        text.length === 0,
    );

    setOrdensFiltradas(newOrdersList);
  }

  function buscarOrdensServico() {
    setFetching(true);

    ProtheusApi.get('/ordem-servico')
      .then((response: AxiosResponse<OrdemServicoInstance[]>) => {
        setOrdensServico(response.data);
        setOrdensFiltradas(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setFetching(false));
  }

  return (
    <Page disablePadding>
      <Box sx={{ width: '100%' }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            padding: '1rem',
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              minHeight: '3rem',
              maxHeight: '3rem',
              minWidth: '3rem',
              maxWidth: '3rem',
            }}
          >
            <IconButton
              aria-label="atualizar"
              sx={{ width: '3rem', aspectRatio: '1', borderRadius: '1rem' }}
              onClick={buscarOrdensServico}
              disabled={isFetching}
            >
              <RefreshRoundedIcon />
            </IconButton>
          </Paper>

          <Paper
            elevation={3}
            sx={{
              minHeight: '3rem',
              maxHeight: '3rem',
            }}
          >
            <ToggleButton
              value="check"
              selected={apenasPendentes}
              disabled={isFetching}
              onChange={() => setApenasPendentes((current) => !current)}
              sx={{ border: 'none' }}
            >
              <AccessTimeRoundedIcon sx={{ marginRight: '.75rem' }} />
              <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                Apenas Pendentes
              </Typography>
            </ToggleButton>
          </Paper>

          <Paper elevation={3} sx={{ minHeight: '3rem', maxHeight: '3rem' }}>
            <InputBase
              placeholder="Pesquisar O.S ou Cliente..."
              inputProps={{ 'aria-label': 'pesquisar' }}
              onChange={(event) => handleSearchFilter(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <SearchRoundedIcon />
                </InputAdornment>
              }
              disabled={isFetching}
              sx={{
                padding: '.5rem 1rem',
                width: '20rem',
              }}
            />
          </Paper>
        </Stack>

        <Box
          sx={{
            padding: '0 1rem 1rem 1rem',
            '@media screen and (maxWidth: 1024px)': '.5rem',
          }}
        >
          <TabelaOS
            ordens={ordensFiltradas}
            isFetching={isFetching}
            refreshCallback={buscarOrdensServico}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default OrdemServico;
