import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Pages
import Login from '../Pages/Login';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Login />} />
    </Routes>
  );
};

export default PublicRoutes;
