import React from 'react';

// Components
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Grid, Paper, Stack } from "@mui/material";
import Chart from 'react-google-charts';
import Page from '../../Components/Page';
import { CustomCard, CustomCardValue, DatePicker, Divider, HalfDivider, OsLine, OsLineLabel, OsLineText } from './styles';

const Dashboard: React.FC = () => {
  return (
    <Page>
      <Stack direction='row' mb={2} alignItems='center'>
        <Paper elevation={3}>
          <DatePicker type="date"/>
        </Paper>
        <HalfDivider/>
        <ArrowForwardRoundedIcon/>
        <HalfDivider/>
        <Paper elevation={3}>
          <DatePicker type="date"/>
        </Paper>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper sx={{flexGrow: 1, padding: 2}}>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomCard>
                  Pendentes
                  <CustomCardValue>
                    4
                  </CustomCardValue>
                </CustomCard>
              </Grid>
              <Grid item xs={6}>
                <CustomCard>
                  Em andamento
                  <CustomCardValue>
                    6
                  </CustomCardValue>
                </CustomCard>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomCard>
                  Finalizadas
                  <CustomCardValue>
                    13
                  </CustomCardValue>
                </CustomCard>
              </Grid>
              <Grid item xs={6}>
                <CustomCard>
                  Abertas da semana passada
                  <CustomCardValue>
                    2
                  </CustomCardValue>
                </CustomCard>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomCard>
                  Tempo médio pendente
                  <CustomCardValue>
                    2h37m
                  </CustomCardValue>
                </CustomCard>
              </Grid>
              <Grid item xs={6}>
                <CustomCard>
                  Tempo médio em atendimento
                  <CustomCardValue style={{color: '#f44336'}}>
                    4h:42m
                  </CustomCardValue>
                </CustomCard>
              </Grid>
            </Grid>
          
          </Paper>
        </Grid>
        
        <Grid item xs={8}>
          <Paper sx={{flexGrow: 1, padding: 2}}>
            Últimas ordens de serviço
            <OsLine style={{color: '#f44336'}}>
              <OsLineLabel>000022</OsLineLabel>
              <Divider/>
              <OsLineText>Leonardo César</OsLineText>
              <Divider/>
              <OsLineText>06/12/2023 - 08:00</OsLineText>
              <Divider/>
              <OsLineText>Finalizada 6h:24m depois de atendida</OsLineText>
            </OsLine>
            <OsLine>
              <OsLineLabel>000021</OsLineLabel>
              <Divider/>
              <OsLineText>João Marcus</OsLineText>
              <Divider/>
              <OsLineText>06/12/2023 - 08:00</OsLineText>
              <Divider/>
              <OsLineText>Finalizada 1h:57m depois de atendida</OsLineText>
            </OsLine>
            <OsLine style={{color: '#f44336'}}>
              <OsLineLabel>000020</OsLineLabel>
              <Divider/>
              <OsLineText>Ueider Rodrigues</OsLineText>
              <Divider/>
              <OsLineText>06/12/2023 - 08:00</OsLineText>
              <Divider/>
              <OsLineText>Finalizada 7h:14m depois de atendida</OsLineText>
            </OsLine>
            <OsLine>
              <OsLineLabel>000019</OsLineLabel>
              <Divider/>
              <OsLineText>Michael Douglas</OsLineText>
              <Divider/>
              <OsLineText>06/12/2023 - 08:00</OsLineText>
              <Divider/>
              <OsLineText>Finalizada 2h:40m depois de atendida</OsLineText>
            </OsLine>
            <OsLine>
              <OsLineLabel>000018</OsLineLabel>
              <Divider/>
              <OsLineText>Ruyter Antonio</OsLineText>
              <Divider/>
              <OsLineText>06/12/2023 - 08:00</OsLineText>
              <Divider/>
              <OsLineText>Finalizada 3h:24m depois de atendida</OsLineText>
            </OsLine>
          </Paper>
          <div style={{height: '1rem'}}/>
          <Paper sx={{flexGrow: 1, padding: 2}}>
            Ordens de Serviço Abertas x Encerradas por período
            <Chart
              style={{ maxWidth: '100%', minWidth: '100%' }}
              width={ '100%' }
              height={ '100%' }
              chartType="AreaChart"
              loader={
                <span>Carregando...</span>
              }
              data={[
                ['Dia', `Abertas`, `Finalizadas`], 
                ["01/12", 3, 3],
                ["02/12", 4, 3],
                ["03/12", 2, 3],
                ["04/12", 5, 3],
                ["05/12", 4, 2],
                ["06/12", 3, 2],
                ["07/12", 6, 4],
                ["01/12", 8, 10],
                ["02/12", 7, 8],
                ["03/12", 6, 5],
                ["04/12", 5, 3],
                ["05/12", 4, 2],
                ["06/12", 3, 2],
                ["07/12", 6, 4]
              ]}
              options={{
                lineWidth: 2,
                pointShape: 'circle',
                pointSize: 4,
                areaOpacity: .2,
                backgroundColor: 'transparent',

                animation: {
                  duration: 250,
                  easing: 'in',
                  startup: true
                },
                
                legend: {
                  position: 'top',
                  alignment: 'start',
                  textStyle: {
                    color: '#f5f5f5'
                  }
                },
                
                chartArea: {
                  backgroundColor: 'transparent',
                  left: 48,
                  right: 48,
                },
                
                hAxis: {
                  slantedText: true,
                  slantedTextAngle: 45,
                  textStyle: {
                    color: '#f5f5f5'
                  }
                },

                vAxis: {
                  minValue: 0,
                  gridlines: {
                    color: '#333',
                    minSpacing: 20
                  },
                  minorGridlines: {
                    color: '#333',
                    minSpacing: 10
                  },
                  textStyle: {
                    color: '#f5f5f5'
                  }
                },

                series: {
                  0: {
                    color: '#f44336',
                  },
            
                  1: {
                    color: '#4CAF50',
                  }
                }
              }}
            />
          </Paper>
        </Grid>

      </Grid>
    </Page>
  );
};

export default Dashboard;
