import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProtheusApi from '../../Connections/protheus';
import columns from '../../Utilities/ColunasOs';
import OsToPdf from '../../Utilities/OsToPdf';

// Components
import {
  Alert,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Slide,
  SlideProps,
  Snackbar,
  TableCell,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

// Styles
import * as Styles from './styles';

// Icons
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

// Types and Interfaces
import { OrdemServicoInstance } from '../../@types/ordemServico';

interface Props {
  row: OrdemServicoInstance;
  refreshCallback: () => void;
}

export const LinhaOS: React.FC<Props> = ({ row, refreshCallback }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [finishDialogOpen, setFinishDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [inProgressDialogOpen, setInProgressDialogOpen] = useState<boolean>(false);

  const [osSelecionada, setOsSelecionada] = useState<OrdemServicoInstance>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const [isDeleting, setDeleting] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleFinishDialogClose() {
    setFinishDialogOpen(false);
  }

  function handleDeleteDialogClose() {
    setDeleteDialogOpen(false);
  }

  function handleInProgressDialogClose() {
    setInProgressDialogOpen(false);
  }

  function handleDeletarOs(numeroOs: string) {
    deletarOrdemServico(numeroOs);
  }

  function handleFinalizarOs(numeroOs: string) {
    finalizarOrdemServico(numeroOs);
  }

  function deletarOrdemServico(numeroOS: string) {
    setDeleting(true);
    handleDeleteDialogClose();

    ProtheusApi.delete(`/ordem-servico/${numeroOS}`)
      .then((response) => {
        setSnackBarMessage('Ordem de Serviço excluída com sucesso!');
        setSnackBarOpen(true);
        refreshCallback();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setDeleting(false);
      });
  }

  function finalizarOrdemServico(numeroOS: string) {
    setDeleting(true);
    handleFinishDialogClose();

    ProtheusApi.put(`/ordem-servico/finalizar/${numeroOS}`)
      .then((response) => {
        setSnackBarMessage('Ordem de Serviço finalizada com sucesso!');
        setSnackBarOpen(true);
        refreshCallback();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setDeleting(false);
      });
  };

  function atenderOrdemServico(numeroOS: string) {
    setDeleting(true);
    handleInProgressDialogClose();

    ProtheusApi.put(`/ordem-servico/atender/${numeroOS}`)
      .then((response) => {
        setSnackBarMessage('Ordem de Serviço atendida com sucesso!');
        setSnackBarOpen(true);
        refreshCallback();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleOpen = (row: OrdemServicoInstance) => {
    setOsSelecionada(row);
    setOpen(true);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleDialogOpen() {
    handleCloseMenu();
    setDialogOpen(true);
  }

  function handleFinishDialogOpen() {
    handleCloseMenu();
    setFinishDialogOpen(true);
  }

  function handleDeleteDialogOpen() {
    handleCloseMenu();
    setDeleteDialogOpen(true);
  }

  function handleinProgressDialogOpen() {
    handleCloseMenu();
    setInProgressDialogOpen(true);
  }

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

  const ServicoConcluido = () => (
    <Chip
      icon={<DoneRoundedIcon />}
      variant="outlined"
      color="success"
      label="Finalizado"
    />
  );

  const ServicoPendente = () => (
    <Chip
      icon={<ErrorOutlineRoundedIcon />}
      variant="outlined"
      color="error"
      label="Pendente"
    />
  );

  const ServicoEmAndamento = () => (
    <Chip
      icon={<AccessTimeRoundedIcon />}
      variant="outlined"
      color="warning"
      label="Em andamento"
    />
  );

  return (
    <Styles.Row hover role="checkbox" tabIndex={-1} key={row.numeroOs}>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell sx={{ border: 0 }} key={column.id} align={column.align}>
            {column.chip ? (
              <Chip color="default" label={value} />
            ) : column.id === 'status' ? (
              row.status === '3' ? (
                <ServicoConcluido />
              ) : row.status === '2' ? (
                <ServicoEmAndamento/>
              ) : <ServicoPendente />
            ) : (
              <>{value || <RemoveRoundedIcon />}</>
            )}
          </TableCell>
        );
      })}
      <TableCell sx={{ border: 0 }} scope="row">
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => handleOpen(row)}>
            <ListItemIcon>
              <VisibilityRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Visualizar</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={row.status === '3'}
            onClick={() => navigate(`/ordem-servico/editar/${row.numeroOs}`)}
          >
            <ListItemIcon>
              <EditRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </MenuItem>
          <PDFDownloadLink
            document={OsToPdf(row)}
            fileName={`ordem-servico-${row.numeroOs}`}
            style={{
              color: '#F5F5F5',
              textDecoration: 'none',
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <FileDownloadRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </MenuItem>
          </PDFDownloadLink>
          <Divider />
          
          <MenuItem onClick={handleinProgressDialogOpen} disabled={row.status !== '1'}>
            <ListItemIcon>
              <BuildRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Atender</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleFinishDialogOpen} disabled={row.status !== '2'}>
            <ListItemIcon>
              <AssignmentTurnedInRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Finalizar</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleDeleteDialogOpen}>
            <ListItemIcon>
              <DeleteRoundedIcon fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText sx={{ color: '#f44336' }}>Excluir</ListItemText>
          </MenuItem>

        </Menu>
        <IconButton onClick={handleClickMenu}>
          <MoreVertIcon />
        </IconButton>
      </TableCell>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deseja realmente excluir a OS?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao excluir a ordem de serviço, todos os dados inseridos serão
            perdidos
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
          <Button onClick={() => handleDeletarOs(row.numeroOs)}>
            Excluir O.S.
          </Button>
          <Button onClick={handleDeleteDialogClose} autoFocus variant="contained">
            Voltar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={finishDialogOpen}
        onClose={handleFinishDialogClose}
        aria-labelledby="alert-finish-dialog-title"
      >
        <DialogTitle id="alert-finish-dialog-title">
          {'Deseja realmente finalizar a OS?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao finalizar a ordem de serviço, não será possível editar nenhuma
            informação da O.S.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
          <Button onClick={() => handleFinalizarOs(row.numeroOs)}>
            Finalizar O.S.
          </Button>
          <Button
            onClick={handleFinishDialogClose}
            autoFocus
            variant="contained"
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={inProgressDialogOpen}
        onClose={handleinProgressDialogOpen}
        aria-labelledby="alert-progress-dialog-title"
      >
        <DialogTitle id="alert-progress-dialog-title">
          {'Deseja realmente atender a OS?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao confirmar, você irá começar o atendimento da ordem de serviço
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
          <Button onClick={() => atenderOrdemServico(row.numeroOs)}>
            Atender O.S.
          </Button>
          <Button
            onClick={handleFinishDialogClose}
            autoFocus
            variant="contained"
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Paper elevation={3} sx={{ height: '90%', aspectRatio: '210/297' }}>
          <PDFViewer
            showToolbar={true}
            style={{ width: '100%', height: '100%', borderRadius: '.5rem' }}
          >
            {OsToPdf(osSelecionada)}
          </PDFViewer>
        </Paper>
      </Backdrop>

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{
          backgroundColor: '#000000dd',
          color: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isDeleting}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Styles.Row>
  );
};
