export class Masks {
  static CPF(value: string) {
    let maskedValue;
  
    maskedValue = value.replace(/\D/g,"")
    maskedValue = maskedValue.replace(/(\d{3})(\d)/,"$1.$2");
    maskedValue = maskedValue.replace(/(\d{3})(\d)/,"$1.$2");
    maskedValue = maskedValue.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
  
    return maskedValue;
  };
  
  static CNPJ(value: string){
    let maskedValue;
  
    maskedValue = value.replace(/\D/g,"");
    maskedValue = maskedValue.replace(/^(\d{2})(\d)/,"$1.$2");
    maskedValue = maskedValue.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
    maskedValue = maskedValue.replace(/\.(\d{3})(\d)/,".$1/$2");
    maskedValue = maskedValue.replace(/(\d{4})(\d)/,"$1-$2");
    
    return maskedValue;
  };
};