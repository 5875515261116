import React, { useState } from 'react';

// Contexts
import { useAuth } from '../../../Contexts/AuthContext';

// Components
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Icons

// Styles
import Drawer from '../../Navigation/Drawer';

export default function SimpleAppBar() {
  const { logout } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

  function toggleDrawer() {
    setDrawerOpen((current) => !current);
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const options = [
    { title: 'Perfil', action: () => {} },
    { title: 'Notificações', action: () => {} },
    { title: 'Configurações', action: () => {} },
    { title: 'Sobre', action: () => {} },
    { title: 'Sair', action: logout },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ padding: '1rem 0', borderRadius: '0 0 1rem 1rem' }}
      >
        <Toolbar variant="dense">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            fontWeight="medium"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Manutenção expresso
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <Stack direction="row" spacing={3}>
              <Tooltip title="Abrir configurações" arrow>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="Leonardo César"
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {options.map((option) => (
                <MenuItem key={option.title} onClick={option.action}>
                  <Typography textAlign="center">{option.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer opened={isDrawerOpen} toggleDrawer={toggleDrawer} />
    </Box>
  );
}
