import { Paper } from '@mui/material';
import styled from 'styled-components';

// Assets
import LowPolyGridDark from '../../Assets/low-poly-grid-dark.svg';
import Rancheiro2x from '../../Assets/rancheiro-2x.png';

export const Container = styled.section`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${LowPolyGridDark});
  background-position: center;
  background-size: cover;
  image-rendering: optimizeQuality;

  @media only screen and (max-width: 720px) {
    padding: 1rem;
  }
`;

export const Content = styled(Paper)`
  /* width: 30rem; */

  /* margin-right: calc(25% - (25rem/2)); */

  @media only screen and (max-width: 720px) {
    width: 100%;
    margin-right: unset;
  }
`;

export const Form = styled.form`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem 1rem 0 1rem;

  &::after {
    content: '';
    width: 100%;
    height: 0.063rem;

    margin-top: 2rem;

    background-color: var(--medium-grey);
  }
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 1rem;
  border-radius: 1rem 1rem 0 0;

  background-color: var(--purple);
`;

export const Footer = styled.footer`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  border-radius: 0 0 0.25rem 0.25rem;

  color: var(--medium-grey);

  font-weight: bold;
  font-size: 0.9rem;
`;

export const RancheiroLogo = styled.img.attrs({
  src: Rancheiro2x,
  alt: 'Logo Café Rancheiro',
})`
  height: 3rem;

  @media only screen and (max-width: 720px) {
    height: 2.5rem;
  }
`;

export const Title = styled.h1`
  color: var(--light);
  text-transform: capitalize;
  margin-left: 1rem;
  white-space: nowrap;
`;
